import React from 'react';
import './TopSection.css'; // Ensure this CSS file is in the same directory as your component
import { ReactComponent as TwitterXIcon } from '../icons/twitterX/icons8-twitterx-100.svg';
import { ReactComponent as YoutubeIcon } from '../icons/youtube/icons8-youtube-100.svg';
import { ReactComponent as TiktokIcon } from '../icons/tiktok/icons8-tiktok-100.svg';
import { ReactComponent as InstagramIcon } from '../icons/instagram/icons8-instagram-100.svg';
import jesusmartinez from '../media/smile3.png';
import r3 from '../media/logo_2_4.png';
import l3gion from '../media/l3gion1x1.png';
import { Tilt } from 'react-tilt';
import BlockchainGrid from './BlockchainGrid'; // Make sure the path is correct


const TopSection = () => {
    return (
        <section className="top-section">
            <BlockchainGrid className='blockchainElement' /> {/* Render the animated grid */}
            <img src={l3gion} alt="L3GION" className="middle-right-image" />
            <div className="content">



                <div className='heroPT1'>
                    <h1 className='nameh1'>Jesus<br />Martinez</h1>

                </div>
                <div className='heroPT2'>

                    {/* <h2>Web3 Gaming Content Creator</h2> */}

                    <h2 className='cta-h2'><span className='keyword'>Supercharge</span> <span className='text'>your wallet by joining our Discord</span> <span className='keyword'>L3GION</span>. </h2>

                    <div className="image-container">
                        <img className='jesus-martinez-image' src={jesusmartinez} alt="Jesus Martinez" />
                    </div>
                    <div className='cta-button-container'>
                        <a href='http://www.l3gion.gg/' target="_blank" rel="noopener noreferrer">
                            <button className="cta-button btn1">JOIN L3GION</button>
                        </a>
                        <a href='/top-sponsors' target="_blank" rel="noopener noreferrer">
                            <button className="cta-button btn2">Top Sponsor</button>
                        </a>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default TopSection;