import React from 'react';
import './TopSponsors.css'; // Ensure this CSS file is in the same directory
import sponsorLogo1 from '../media/sponsors/wilderWorld.png'; // Replace with your sponsor logos
import BlockchainGrid from './BlockchainGrid'; 

// Import more sponsor logos as needed

const TopSponsors = () => {
    const sponsors = [
        {
            name: 'Wilder World',
            logo: sponsorLogo1,
            website: 'https://market.wilderworld.com/loyalty?referral_code=27SETBXR',
        },

        // Add more sponsors here
    ];

    return (
        <section className="parentBG">
            <div className="top-sponsors-section">
                <div className="sponsors-content">
                <BlockchainGrid className='blockchainElement' /> {/* Render the animated grid */}
                    <h1 className="section-title">Top Sponsor</h1>
                    <div className="sponsors-grid">
                        {sponsors.map((sponsor, index) => (
                            <div key={index} className="sponsor-card">
                                <a href={sponsor.website} target="_blank" rel="noopener noreferrer" className='link'>
                                    <h2 className="sponsor-name">{sponsor.name}</h2>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TopSponsors;
